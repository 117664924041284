import { eventStreamService } from 'core-roblox-utilities';

const { eventTypes } = eventStreamService;
const CONTEXT = 'MultiverseSignupForm';

const events = {
  DefaultVerifiedSignupOrigin: 'WebVerifiedSignup',

  emailFocused: {
    name: 'emailFocused',
    context: CONTEXT,
    type: eventTypes.formInteraction,
    params: {
      aType: 'focus',
      field: 'email'
    }
  },
  phoneFocused: {
    name: 'phoneFocused',
    context: CONTEXT,
    type: eventTypes.formInteraction,
    params: {
      aType: 'focus',
      field: 'phone'
    }
  },
  useEmail: {
    name: 'useEmail',
    context: CONTEXT,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      field: 'useEmail'
    }
  },
  usePhone: {
    name: 'usePhone',
    context: CONTEXT,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      field: 'usePhone'
    }
  }
};

export { events as default };
