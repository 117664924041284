import {
  EnvironmentUrls,
  UrlParser,
  FormEvents,
  Intl,
  Resources,
  Auth,
  SignupMeta,
  PhoneNumberVerificationService
} from 'Roblox';
import { urlService } from 'core-utilities';
import { localStorageService } from 'core-roblox-utilities';
import events from '../constants/verifiedSignupEventStreamConstants';
import landingPageModule from '../landingPageModule';

const { parseUrl } = urlService;

function signupController(
  $injector,
  $scope,
  $log,
  $timeout,
  httpService,
  urlService,
  eventTrackerService,
  signupService,
  signupConstants,
  captchaV2Constants,
  languageResource,
  eventStreamService,
  phoneService,
  regexService,
  modalService
) {
  'ngInject';

  $scope.signup = {};
  $scope.layout = {
    context: signupConstants.context,
    passwordInputType: signupConstants.inputType.password,
    isFirstSignUpSubmit: true,
    orderedBirthdayParts: {
      parts: signupConstants.defaultDateParts,
      typeOrder: signupConstants.defaultDateOrdering
    },
    isSubmitting: false
  };
  $scope.signUpParams = $scope.signUpParams || {};
  const intl = new Intl();

  $scope.genderType = signupConstants.genderType;
  $scope.signup.gender = $scope.genderType.unknown;
  $scope.birthdayToPrefill = $scope.$parent.birthdayToPrefill;

  $scope.captchaActivated = false;
  $scope.captchaActionTypes = captchaV2Constants.captchaActionTypes;
  $scope.emailRegex = signupConstants.emailRegex;

  function setMonthList() {
    $scope.layout.months = [
      { value: 'Jan', label: languageResource.get('Label.January') },
      { value: 'Feb', label: languageResource.get('Label.February') },
      { value: 'Mar', label: languageResource.get('Label.March') },
      { value: 'Apr', label: languageResource.get('Label.April') },
      { value: 'May', label: languageResource.get('Label.May') },
      { value: 'Jun', label: languageResource.get('Label.June') },
      { value: 'Jul', label: languageResource.get('Label.July') },
      { value: 'Aug', label: languageResource.get('Label.August') },
      { value: 'Sep', label: languageResource.get('Label.September') },
      { value: 'Oct', label: languageResource.get('Label.October') },
      { value: 'Nov', label: languageResource.get('Label.November') },
      { value: 'Dec', label: languageResource.get('Label.December') }
    ];
    const placeholder = languageResource.get('Label.Month');
    setOrderedBirthdayPart('month', $scope.layout.months, placeholder);
  }

  function setDateList() {
    const dates = [];
    const maximumDate = signupConstants.maxNumberOfDates;

    for (let i = 1; i <= maximumDate; i++) {
      // add leading zero (1 => 01 and 10 => 10)
      const day = `0${i}`.slice(-2);
      const i18nDay = {
        day,
        value: day,
        label: $scope.isAsianBirthdayUsed
          ? intl.getFormattedDateString(day, languageResource.get('Label.Day'))
          : day
      };
      dates.push(i18nDay);
    }

    $scope.layout.dates = dates;

    const placeholder = languageResource.get('Label.Day');
    setOrderedBirthdayPart('day', dates, placeholder);
  }

  function setYearList() {
    const years = [];
    const now = new Date();
    const currentYear = now.getFullYear();
    const minimumYear = currentYear - signupConstants.maxSignUpAge;

    for (let i = currentYear; i > minimumYear; i--) {
      const i18nYear = {
        year: i,
        value: i,
        label: $scope.isAsianBirthdayUsed
          ? intl.getFormattedDateString(i, languageResource.get('Label.Year'))
          : i
      };
      years.push(i18nYear);
    }

    $scope.layout.years = years;

    const placeholder = languageResource.get('Label.Year');
    setOrderedBirthdayPart('year', years, placeholder);
  }

  function setOrderedBirthdayPart(partName, value, placeholder) {
    const partIndex = $scope.layout.orderedBirthdayParts.typeOrder[partName];

    $scope.layout.orderedBirthdayParts.parts[partIndex] = {
      options: value,
      idName: signupConstants.birthdayPicker[partName].id,
      className: signupConstants.birthdayPicker[partName].class,
      birthdayName: signupConstants.birthdayPicker[partName].name,
      placeholder
    };
  }

  function buildLinkWithLocale(url, locale) {
    if (locale) {
      return url + signupConstants.localeParamName + locale;
    }

    return url;
  }

  function getLocale() {
    const locale = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.urlQueryNames.locale)
      : null;
    return locale ? encodeURIComponent(locale) : null;
  }

  function getReferralData() {
    let source = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxSource)
      : null;
    let campaign = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxCampaign)
      : null;
    let medium = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxMedium)
      : null;
    let requestSessionId = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.reqId)
      : null;
    let offerId = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.offerId)
      : null;

    source = source ? encodeURIComponent(source) : null;
    campaign = campaign ? encodeURIComponent(campaign) : null;
    medium = medium ? encodeURIComponent(medium) : null;
    requestSessionId = requestSessionId ? encodeURIComponent(requestSessionId) : null;
    offerId = offerId ? encodeURIComponent(offerId) : null;

    if (!source && !campaign && !medium && !requestSessionId && !offerId) {
      return null;
    }

    return {
      source,
      campaign,
      medium,
      requestSessionId,
      offerId
    };
  }

  function setSignUpFormValidatorResources() {
    if (!Resources) {
      return;
    }

    // TODO: update this once AnimatedSignupFormValidator is moved to authsite
    Resources.AnimatedSignupFormValidator = {
      maxValid: languageResource.get('Response.TooManyAccountsWithSameEmailError'),
      invalidEmail: languageResource.get('Response.InvalidEmail'),
      invalidPhone: languageResource.get('Response.InvalidPhoneNumber'),
      invalidBirthday: languageResource.get('Response.InvalidBirthday'),
      loginFieldsRequired: languageResource.get('Response.UsernamePasswordRequired'),
      loginFieldsIncorrect: languageResource.get('Response.UsernameOrPasswordIncorrect'),
      doesntMatch: languageResource.get('Response.PasswordMismatch'),
      passwordIsUsername: languageResource.get('Response.PasswordContainsUsernameError'),
      requiredField: languageResource.get('Label.Required'),
      passwordBadLength: languageResource.get('Response.PasswordBadLength'),
      weakKey: languageResource.get('Response.PasswordComplexity'),
      invalidCharacters: languageResource.get('Response.SpaceOrSpecialCharaterError'),
      invalidName: languageResource.get('Response.UsernameAllowedCharactersError'),
      cantBeUsed: languageResource.get('Response.BadUsername'),
      cantBeUsedPii: languageResource.get('Response.UsernamePrivateInfo'),
      alreadyTaken: languageResource.get('Response.UsernameAlreadyInUse'),
      userNameInvalidLength: languageResource.get('Response.UsernameInvalidLength'),
      startsOrEndsWithUnderscore: languageResource.get('Response.UsernameInvalidUnderscore'),
      moreThanOneUnderscore: languageResource.get('Response.UsernameTooManyUnderscores'),
      birthdayRequired: languageResource.get('Response.BirthdayMustBeSetFirst'),
      passwordRequired: languageResource.get('Response.PleaseEnterPassword'),
      usernameRequired: languageResource.get('Response.PleaseEnterUsername'),
      passwordConfirmationRequired: languageResource.get('Response.PasswordConfirmation'),
      usernameNoRealNameUse: languageResource.get('Message.Username.NoRealNameUse'),
      passwordMinLength: languageResource.get('Message.Password.MinLength'),
      usernameNotAvailable: languageResource.get('Response.UsernameNotAvailable')
    };
  }

  function prefillBirthdayIfNecessary() {
    if ($scope.birthdayToPrefill) {
      const birthday = new Date($scope.birthdayToPrefill);
      const dateIndex = birthday.getDate() - 1;
      const dateToPrefill = $scope.layout.dates[dateIndex].value;
      $scope.signup.birthdayDay = dateToPrefill;
      const monthIndex = birthday.getMonth();
      const prefillMonth = $scope.layout.months[monthIndex].value;
      $scope.signup.birthdayMonth = prefillMonth;
      const prefillYearInt = birthday.getFullYear();
      const prefillYear = $scope.layout.years.find(({ year }) => year === prefillYearInt).value;
      $scope.signup.birthdayYear = prefillYear.toString();

      // lock birthday if user <18
      const now = new Date();
      const eighteenYearsAgo = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
      const ageDiff = now - birthday;
      $scope.isPrefilledBirthdayUnder18 = ageDiff < now - eighteenYearsAgo;
      $scope.layout.shouldDisableBirthdaySelect = $scope.isPrefilledBirthdayUnder18;
    }
  }

  window.addEventListener(
    'PhoneNumberValidated',
    event => {
      $scope.signup.voucher = event.detail.voucher;
      if ($scope.signup.voucher) {
        $scope.submitSignup(false);
      }
    },
    false
  );

  $scope.init = function () {
    // On web, email is the default (on mobile, it is phone)
    $scope.contactMethodIsEmail = true;
    $scope.isAsianBirthdayUsed = intl && intl.isAsianLanguage();
    $scope.layout.orderedBirthdayParts.typeOrder = new Intl()
      .getDateTimeFormatter()
      .getOrderedDateParts();
    setSignUpFormValidatorResources();
    setMonthList();
    setDateList();
    setYearList();
    prefillBirthdayIfNecessary();

    const locale = getLocale();
    const termsOfUseLink = EnvironmentUrls.websiteUrl + signupConstants.urls.termsOfUse;
    const privacyLink = EnvironmentUrls.websiteUrl + signupConstants.urls.privacy;
    const termsOfUseLocalizedLink = buildLinkWithLocale(termsOfUseLink, locale);
    const privacyLocalizedLink = buildLinkWithLocale(privacyLink, locale);
    $scope.layout.termsOfUseLinkElement = `${
      signupConstants.anchorOpeningTag + termsOfUseLocalizedLink
    }">${languageResource.get('Label.TermsOfUse')}${signupConstants.anchorClosingTag}`;
    $scope.layout.privacyLinkElement = `${
      signupConstants.anchorOpeningTag + privacyLocalizedLink
    }">${languageResource.get('Description.PrivacyPolicy')}${signupConstants.anchorClosingTag}`;

    function fetchUserAgreements() {
      $scope.agreementIds = [];
      signupService
        .getUserAgreements()
        .then(agreements => {
          agreements.forEach(agreement => {
            $scope.agreementIds.push(agreement.id);
          });
        })
        .catch(error => {
          $log.error('signupController fetchUserAgreements error ', error);
        });
    }

    // This call is used for experimentation / AB testing variants
    signupService
      .getMetadataV2()
      .then(data => {
        if (data) {
          $scope.isContactMethodRequiredAtSignup = data.IsContactMethodRequiredAtSignup;
          $scope.IsRetypePasswordRequired = data.IsRetypePasswordRequired;
          $scope.ArePasswordFieldsPlaintext = data.ArePasswordFieldsPlaintext;
          $scope.isUserAgreementsSignupIntegrationEnabled =
            data.IsUserAgreementsSignupIntegrationEnabled;
          $scope.isSignupButtonGreenColorEnabled = data?.IsSignupButtonGreenColorEnabled;
        }

        if ($scope.IsRetypePasswordRequired && $scope.ArePasswordFieldsPlaintext) {
          $scope.layout.showPassword = true;
          $scope.layout.passwordInputType = signupConstants.inputType.text;
        }

        if ($scope.isContactMethodRequiredAtSignup) {
          $scope.getPhonePrefixes();
          $scope.getEmailRegex();
        }

        if ($scope.isUserAgreementsSignupIntegrationEnabled) {
          fetchUserAgreements();
        }
      })
      .catch(error => {
        $log.error('signupController $scope.init error ', error);
      });
  };

  $scope.isParentEmailForKoreaUserEnabled = function () {
    return (
      ($scope.isPrefilledBirthdayUnder18 || $scope.isUnderThresholdAge(18)) &&
      $scope.isKoreaIdVerificationEnabled
    );
  };

  $scope.getEmailRegex = function () {
    regexService.getEmailRegex().then(data => {
      if (data) {
        $scope.emailRegex = data.Regex;
      }
    });
  };

  $scope.handleCaptchaError = function (errorCode) {
    let errorText;

    switch (errorCode) {
      case captchaV2Constants.errorCodes.internal.failedToLoadProviderScript:
        errorText = languageResource.get('Response.CaptchaErrorFailedToLoad');
        break;
      default:
        errorText = languageResource.get('Response.CaptchaErrorFailedToVerify');
    }

    // Accepted way to prevent calling "$digest" again when digest is already in progress
    // If this callback fires as the result of an asynchronous request, we need to trigger
    // $digest manually, which can be accomplished with $timeout. We can't call $scope.$apply
    // because this will cause an error if it is already in progress.
    // https://davidburgos.blog/correctly-fix-angularjs-error-digest-already-in-progress/
    $timeout(function () {
      $scope.isSubmitting = false;
      $scope.signupForm.$generalError = true;
      $scope.signupForm.$generalErrorText = errorText;
    }, 0);
  };

  $scope.handleCaptchaDismiss = function () {
    $scope.layout.isSubmitting = false;
  };

  $scope.handleCaptchaSuccess = function (captchaData) {
    $scope.captchaActivated = false;
    $scope.submitSignup(false, captchaData);
  };

  $scope.setGender = function (e, genderType, lockField) {
    if (e) {
      e.preventDefault();
    }

    if ($scope.layout.isGenderDisabled) {
      return;
    }

    $scope.signup.gender =
      $scope.signup.gender === genderType ? $scope.genderType.unknown : genderType;
    $scope.layout.isGenderDisabled = lockField;
  };

  /* START BIRTHDAY FUNCTIONS */

  $scope.isBirthdayFormDirty = function () {
    // Birthday Form can only be dirty when it exists
    if (
      $scope.signupForm.birthdayMonth &&
      $scope.signupForm.birthdayDay &&
      $scope.signupForm.birthdayYear
    ) {
      return (
        $scope.signupForm.birthdayMonth.$dirty &&
        $scope.signupForm.birthdayDay.$dirty &&
        $scope.signupForm.birthdayYear.$dirty &&
        $scope.signupForm.birthdayMonth.$modelValue !== null &&
        $scope.signupForm.birthdayDay.$modelValue !== null &&
        $scope.signupForm.birthdayYear.$modelValue !== null
      );
    }
    return false;
  };

  $scope.isBirthdayInvalid = function () {
    return (
      ($scope.badSubmit || $scope.isBirthdayFormDirty()) &&
      (!$scope.isValidBirthday($scope.signup.birthdayDay) ||
        $scope.signupForm.birthdayYear.$invalid)
    );
  };

  $scope.getBirthdayInvalidMessage = function () {
    return $scope.isBirthdayInvalid() ? languageResource.get('Response.InvalidBirthday') : '';
  };

  $scope.isValidBirthday = function (day, onlyValidateDay) {
    let year = $scope.signup.birthdayYear;

    if (!year && onlyValidateDay) {
      // set to non-leap year to be conservative when determining which days are available for the selected month
      year = signupConstants.nonLeapYear;
    }

    const month = $scope.signup.birthdayMonth;
    day = parseInt(day); // convert day to integer

    if (!year || !month || !day) {
      return false;
    }

    // Make sure we can create a valid date object
    const testDate = new Date(`${month} ${day} ${year}`);
    if (Object.prototype.toString.call(testDate) !== '[object Date]' || isNaN(testDate.getTime())) {
      return false;
    }

    // checks that it is actually a valid day in that month (like feb 31 doesn't exist but would generate a valid Date)
    if (testDate.getDate() !== day) {
      return false;
    }

    // age limits
    const today = new Date();
    const isBirthdayValid =
      testDate.getTime() < today.getTime() && testDate.getFullYear() > today.getFullYear() - 100;

    if (!isBirthdayValid) {
      return false;
    }

    return true;
  };

  $scope.isUnder13 = function () {
    return $scope.isUnderThresholdAge(13);
  };

  $scope.isUnderThresholdAge = function (age) {
    const year = $scope.signup.birthdayYear;
    const month = $scope.signup.birthdayMonth;
    const day = $scope.signup.birthdayDay;

    if (!year || !month || !day) {
      return false;
    }

    const testDate = new Date(`${month} ${day} ${year}`);
    const now = new Date();
    now.setFullYear(now.getFullYear() - age);
    return testDate > now;
  };

  /* END BIRTHDAY FUNCTIONS */

  /* START CONTACT METHOD FUNCTIONS */
  $scope.getContactMethodLabelText = function () {
    if ($scope.contactMethodIsEmail) {
      return $scope.getEmailLabelText();
    }
    return $scope.getPhoneLabelText();
  };

  $scope.switchToPhone = function () {
    signupService.sendSignupEvent(events.usePhone);
    $scope.contactMethodIsEmail = false;
  };

  $scope.switchToEmail = function () {
    signupService.sendSignupEvent(events.useEmail);
    $scope.contactMethodIsEmail = true;
  };

  $scope.onEmailFocused = function () {
    if ($scope.isContactMethodRequiredAtSignup) {
      signupService.sendSignupEvent(events.emailFocused);
    }
  };

  $scope.onPhoneFocused = function () {
    signupService.sendSignupEvent(events.phoneFocused);
  };

  /* START EMAIL FUNCTIONS */
  $scope.shouldOfferEmailAtSignup = function () {
    return $scope.isContactMethodRequiredAtSignup || $scope.isParentEmailForKoreaUserEnabled();
  };

  $scope.canChooseEmailOrPhoneNumberAtSignup = function () {
    if ($scope.isParentEmailForKoreaUserEnabled()) {
      return false;
    }

    if ($scope.isUnder13()) {
      return false;
    }

    return $scope.isContactMethodRequiredAtSignup;
  };

  $scope.hasValidEmail = function () {
    if (!$scope.shouldOfferEmailAtSignup()) {
      return true;
    }

    if (!$scope.signup.email) {
      return false;
    }
    const re = new RegExp($scope.emailRegex);
    return re.test($scope.signup.email);
  };

  $scope.shouldShowEmailField = function () {
    return $scope.shouldOfferEmailAtSignup() && $scope.contactMethodIsEmail;
  };

  $scope.getEmailPlaceholderText = function () {
    return $scope.isUnder13()
      ? languageResource.get('Label.EmailRequirementsUnder13')
      : languageResource.get('Label.Email');
  };

  $scope.getEmailLabelText = function () {
    const isUnder13 = $scope.isUnder13();

    if ($scope.isParentEmailForKoreaUserEnabled()) {
      return languageResource.get('Label.EmailRequirementsUnder13');
    }

    return isUnder13
      ? languageResource.get('Label.EmailRequirementsUnder13')
      : languageResource.get('Label.Email');
  };

  $scope.getSwitchToPhoneText = function () {
    return `${languageResource.get('Action.UsePhoneNumber')} →`;
  };

  /* END EMAIL FUNCTIONS */

  /* START PHONE NUMBER FUNCTIONS */
  $scope.shouldShowPhoneNumberField = function () {
    if (!$scope.canChooseEmailOrPhoneNumberAtSignup()) {
      return false;
    }
    return !$scope.contactMethodIsEmail;
  };

  $scope.getPhoneLabelText = function () {
    return languageResource.get('Label.PhoneNumber');
  };

  $scope.getSwitchToEmailText = function () {
    return `${languageResource.get('Action.UseEmail')} →`;
  };

  $scope.hasValidPhoneNumber = function () {
    // User input, must be a minimum of 4 characters and
    // can only contain digits and special characters
    // xxxxxxxxxx, xxx-xxx-xxxx, xx xxx xxx, etc
    return (
      $scope.isContactMethodRequiredAtSignup &&
      phoneService.isPhoneNumber($scope.signup.phoneNumber)
    );
  };

  $scope.getPhonePrefixes = function () {
    phoneService.getPhonePrefixes(EnvironmentUrls.apiProxyUrl).then(function success(data) {
      $scope.phonePrefixes = data;

      // set default
      if ($scope.phonePrefixes) {
        $scope.signup.phonePrefix = $scope.phonePrefixes[0].prefix;
        $scope.signup.countryCode = $scope.phonePrefixes[0].code;
      }
    });
  };

  /* END PHONE NUMBER FUNCTIONS */

  $scope.togglePasswordVisibility = function (isPrimaryPasswordField) {
    let field;
    if ($scope.layout.showPassword) {
      $scope.layout.showPassword = false;
      $scope.layout.passwordInputType = signupConstants.inputType.password;
      field = isPrimaryPasswordField
        ? signupConstants.events.fields.hidePassword
        : signupConstants.events.fields.hideRetypePassword;
    } else {
      $scope.layout.showPassword = true;
      $scope.layout.passwordInputType = signupConstants.inputType.text;
      field = isPrimaryPasswordField
        ? signupConstants.events.fields.showPassword
        : signupConstants.events.fields.showRetypePassword;
    }
    eventStreamService.sendEventWithTarget(
      signupConstants.events.buttonClick,
      $scope.signupForm.context,
      {
        field
      }
    );
  };

  // this function is only used to validate form with no contact method field.
  $scope.isFormValid = function () {
    return $scope.signupForm.$valid;
  };

  let wasPasswordBoxClicked = false;
  $scope.passwordBoxClicked = function () {
    wasPasswordBoxClicked = true;
  };

  $scope.getHintForUsername = function () {
    if (!$scope.signup.username && !$scope.isSignupFormDarkThemeEnabled) {
      return Resources.AnimatedSignupFormValidator.usernameNoRealNameUse;
    }
    return $scope.badSubmit || $scope.signupForm.signupUsername.$dirty
      ? $scope.signupForm.signupUsername.$validationMessage
      : '';
  };

  $scope.getUsernamePlaceholder = function () {
    if (!$scope.isSignupFormDarkThemeEnabled) {
      return languageResource.get('Label.Username');
    }
    return Resources.AnimatedSignupFormValidator.usernameNoRealNameUse;
  };

  $scope.getPasswrodPlaceholder = function () {
    if ($scope.isSignupFormDarkThemeEnabled) {
      return languageResource.get('Label.PasswordPlaceholder');
    }
    return languageResource.get('Label.Password');
  };

  $scope.getHintForPassword = function () {
    if (!$scope.signup.password && wasPasswordBoxClicked && !$scope.isSignupFormDarkThemeEnabled) {
      return Resources.AnimatedSignupFormValidator.passwordMinLength;
    }
    if (!$scope.signup.password && $scope.isSignupFormDarkThemeEnabled) {
      return '';
    }
    return $scope.badSubmit || $scope.signupForm.signupPassword.$dirty
      ? $scope.signupForm.signupPassword.$validationMessage
      : '';
  };

  /* START RETYPE PASSWORD FUNCTIONS */
  $scope.getRetypePasswordPlaceholder = function () {
    if ($scope.isSignupFormDarkThemeEnabled) {
      return languageResource.get('Label.RetypePasswordPlaceholder');
    }
    return languageResource.get('Label.RetypePassword');
  };

  // Show an error if:
  // 1. Both pw fields are dirty and the fields do not match OR
  // 2. Bad submit + one of the pw fields is not dirty while the other has text inside
  $scope.getHintForRetypePassword = function () {
    return ($scope.signupForm.retypePassword.$dirty &&
      $scope.signupForm.signupPassword.$dirty &&
      $scope.signup.password != $scope.signup.retypePassword) ||
      ($scope.badSubmit &&
        ((!$scope.signupForm.retypePassword.$dirty &&
          $scope.signupForm.signupPassword.$dirty &&
          $scope.signup.password.length > 0) ||
          (!$scope.signupForm.signupPassword.$dirty &&
            $scope.signupForm.retypePassword.$dirty &&
            $scope.signup.retypePassword.length > 0)))
      ? languageResource.get('Response.PasswordMismatch')
      : '';
  };
  /* END RETYPE PASSWORD FUNCTIONS */

  $scope.hasEmailError = function () {
    if ($scope.badSubmit || $scope.signupForm.signupEmail.$dirty) {
      if ($scope.isBirthdayInvalid()) {
        return true;
      }
      if (!$scope.hasValidEmail()) {
        return true;
      }
    }
    return false;
  };

  $scope.getErrorMessageForEmail = function () {
    if ($scope.hasEmailError()) {
      if ($scope.isBirthdayInvalid()) {
        return Resources.AnimatedSignupFormValidator.birthdayRequired;
      }
      return Resources.AnimatedSignupFormValidator.invalidEmail;
    }
    return '';
  };

  $scope.hasPhoneNumberError = function () {
    if ($scope.badSubmit || $scope.signup.phoneNumber) {
      if ($scope.isBirthdayInvalid()) {
        return true;
      }
      if (!$scope.hasValidPhoneNumber()) {
        return true;
      }
    }
    return false;
  };

  $scope.getErrorMessageForPhone = function () {
    if ($scope.hasPhoneNumberError()) {
      if ($scope.isBirthdayInvalid()) {
        return Resources.AnimatedSignupFormValidator.birthdayRequired;
      }
      return Resources.AnimatedSignupFormValidator.invalidPhone;
    }
    return '';
  };

  $scope.hasContactMethodError = function () {
    if ($scope.contactMethodIsEmail) {
      return $scope.hasEmailError();
    }
    return $scope.hasPhoneNumberError();
  };

  $scope.hasValidContactMethod = function () {
    if ($scope.contactMethodIsEmail) {
      return $scope.hasValidEmail();
    }
    return $scope.hasValidPhoneNumber();
  };

  $scope.setGeneralError = function (text) {
    $scope.signupForm.$generalError = true;
    $scope.signupForm.$generalErrorText = text;
  };

  $scope.showIdentityVerificationTokenErrorModal = function () {
    const modal = modalService.open({
      titleText: languageResource.get('Title.VerificationError'),
      bodyText: languageResource.get('Description.VerificationNotComplete'),
      neutralButtonText: languageResource.get('Action.TryAgain'),
      onNeutral: identityVerificationResultTokenErrorHandler
    });
    modal.result.then(
      function () {},
      function () {
        identityVerificationResultTokenErrorHandler();
      }
    );
  };

  $scope.handleSignupForbiddenErrors = function (response) {
    let numberOfReasonsHandled = 0;
    const errorReasons = response?.reasons || response?.errors[0]?.message;
    if (errorReasons.indexOf('Captcha') !== -1) {
      const inputParams = { unifiedCaptchaId: '', dataExchange: '' };
      if (response.errors && response.errors.length > 0 && response.errors[0].fieldData) {
        const dataArray = response.errors[0].fieldData.split(',');
        // Need this check for backward compatibility with the backend version
        // that doesn't support captcha id.
        if (dataArray.length == 1) {
          inputParams.dataExchange = response.errors[0].fieldData;
        }
        if (dataArray.length == 2) {
          const [captchaId, dxBlob] = dataArray;
          inputParams.unifiedCaptchaId = captchaId;
          inputParams.dataExchange = dxBlob;
        }
      }
      handleCaptcha(inputParams);
      numberOfReasonsHandled += 1;
    }
    if (errorReasons.indexOf('PasswordInvalid') !== -1) {
      $scope.signupForm.signupPassword.$setValidity('password', false);
      $scope.signupForm.signupPassword.$passwordMessage = 'Password is invalid';
      $scope.incrementEphemeralCounter(signupConstants.counters.passwordInvalid);
      numberOfReasonsHandled += 1;
    }
    if (errorReasons.indexOf('UsernameInvalid') !== -1) {
      $scope.signupForm.signupUsername.$setValidity('validusername', false);
      $scope.signupForm.signupUsername.$usernameMessage = 'Username is invalid';
      $scope.incrementEphemeralCounter(signupConstants.counters.usernameInvalid);
      numberOfReasonsHandled += 1;
    }
    if (errorReasons.indexOf('UsernameTaken') !== -1) {
      $scope.signupForm.signupUsername.$setValidity('unique', false);
      $scope.signupForm.signupUsername.$usernameMessage = 'Username is taken';
      $scope.incrementEphemeralCounter(signupConstants.counters.usernameTaken);
      numberOfReasonsHandled += 1;
    }
    if (errorReasons.indexOf('InvalidIdentityVerificationResultToken') !== -1) {
      $scope.incrementEphemeralCounter(
        signupConstants.counters.identityVerificationResultTokenInvalid
      );
      numberOfReasonsHandled += 1;
      $scope.showIdentityVerificationTokenErrorModal();
    }

    if (errorReasons.indexOf('IdentityVerificationFailed') !== -1) {
      $scope.incrementEphemeralCounter(signupConstants.counters.identityVerificationFailed);
      numberOfReasonsHandled += 1;
      $scope.showIdentityVerificationTokenErrorModal();
    }

    const errorCount = typeof errorReasons === 'string' ? 1 : errorReasons?.length || 0;
    if (numberOfReasonsHandled < errorCount) {
      $scope.incrementEphemeralCounter(signupConstants.counters.unknownError);
      $scope.setGeneralError(signupConstants.generalErrorText);
    }
  };

  $scope.incrementSignUpSubmitCounters = function () {
    $scope.incrementEphemeralCounter(signupConstants.counters.attempt);

    if ($scope.layout.isFirstSignUpSubmit) {
      $scope.layout.isFirstSignUpSubmit = false;
      $scope.incrementEphemeralCounter(signupConstants.counters.firstAttempt);
    }
  };

  $scope.badSubmit = false;

  $scope.sendConversionEvent = function (callback) {
    if (typeof gtag === 'undefined' || !gtag || !gtag.conversionEvents) return callback();
    // In case gtag fails
    const id = setTimeout(callback, 2000);
    gtag('event', 'conversion', {
      send_to: gtag.signupConversionEvent,
      event_callback() {
        clearTimeout(id);
        callback();
      },
      event_timeout: 2000
    });
  };

  $scope.submitSignup = function (isUserTriggered, captchaData) {
    if (isUserTriggered) {
      $scope.sendInteractionClickEvent(signupConstants.signUpSubmitButtonName);
    }
    if (
      !$scope.isFormValid() ||
      !$scope.isValidBirthday($scope.signup.birthdayDay) ||
      !$scope.hasValidContactMethod()
    ) {
      $scope.badSubmit = true;
      return;
    }
    $scope.badSubmit = false;

    if ($scope.hasValidPhoneNumber() && !$scope.contactMethodIsEmail && !$scope.signup.voucher) {
      const phoneNumberParams = {
        phonePrefix: $scope.signup.phonePrefix,
        phoneNumber: $scope.signup.phoneNumber,
        countryCode: $scope.signup.countryCode
      };
      return PhoneNumberVerificationService?.handlePhoneNumberVerificationAtSignup(
        phoneNumberParams,
        handleUsePhoneCallback,
        handleUseEmailCallback
      );
    }

    $scope.layout.isSubmitting = true;

    if (isUserTriggered) {
      $scope.incrementSignUpSubmitCounters();
    }

    const birthday = `${$scope.signup.birthdayDay} ${$scope.signup.birthdayMonth} ${$scope.signup.birthdayYear}`;
    const referralData = getReferralData();

    const signUpParams = {
      username: $scope.signup.username,
      password: $scope.signup.password,
      voucher: $scope.signup.voucher,
      birthday,
      gender: $scope.signup.gender,
      isTosAgreementBoxChecked: true,
      context: $scope.signupForm.context,
      referralData
    };

    const locale = getLocale();

    if (locale) {
      signUpParams.locale = locale;
    }

    if ($scope.signup.email) {
      signUpParams.email = $scope.signup.email;
    }

    if (captchaData != null) {
      signUpParams.captchaId = captchaData.captchaId;
      signUpParams.captchaToken = captchaData.captchaToken;
      signUpParams.captchaProvider = captchaData.captchaProvider;
    }

    if (
      $scope.isUserAgreementsSignupIntegrationEnabled &&
      Array.isArray($scope.agreementIds) &&
      $scope.agreementIds.length > 0
    ) {
      signUpParams.agreementIds = $scope.agreementIds;
    }

    const identityVerificationResultToken = localStorageService.getLocalStorage(
      signupConstants.identityVerificationResultToken
    );
    if (identityVerificationResultToken) {
      signUpParams.identityVerificationResultToken = identityVerificationResultToken;
    }

    if (signUpParams.voucher && !$scope.contactMethodIsEmail) {
      // only use verified signup when contact method is phone
      signupService.verifiedSignup(signUpParams).then(
        function (result) {
          handleCallbackAfterSignup();
        },
        function (result) {
          $scope.handleSignupError(result.data, result.status);
        }
      );
    } else {
      signupService.signup(signUpParams).then(
        function success(result) {
          handleCallbackAfterSignup();
        },
        function error(result) {
          $scope.handleSignupError(result.data, result.status);
        }
      );
    }
  };

  $scope.handleSignupError = function (data, status) {
    $scope.badSubmit = true;
    $scope.layout.isSubmitting = false;

    if (status === 403) {
      $scope.handleSignupForbiddenErrors(data);
    } else if (status === 429) {
      $scope.setGeneralError(languageResource.get(signupConstants.unknownErrorTranslationKey));
      $scope.incrementEphemeralCounter(signupConstants.counters.tooManyAttempts);
    } else if (
      data.reasons.some(
        reason => reason === signupConstants.userAgreementsInsertAcceptancesFailureReason
      )
    ) {
      $scope.setGeneralError(
        languageResource.get(signupConstants.accountCreatedButLoginFailedTranslationKey)
      );
    } else {
      // if signup fails, we cannot reuse this voucher
      $scope.signup.voucher = null;
      $scope.setGeneralError();
      $scope.incrementEphemeralCounter(signupConstants.counters.unknownError);
    }
  };

  $scope.incrementEphemeralCounter = function (name) {
    logEvent(signupConstants.counters.prefix + name);
  };

  $scope.sendInteractionClickEvent = function (fieldName) {
    if (FormEvents) {
      FormEvents.SendInteractionClick($scope.signupForm.context, fieldName);
    }
  };

  function handleCallbackAfterSignup() {
    cleanupIdentityVerificationResultToken();
    $scope.incrementEphemeralCounter(signupConstants.counters.success);
    const params = urlService.getJsonFromQueryString();

    let parsedReturnUrl = urlService.getAbsoluteUrl(signupConstants.urls.homePage);

    if (params?.returnurl) {
      parsedReturnUrl = parseUrl(params.returnurl).path;
    }

    // deprioritize Auth.returnUrl since it is set only in loginService
    let returnUrl = parsedReturnUrl || (Auth && Auth.returnUrl);
    if (typeof returnUrl === 'string' && returnUrl.length > 0) {
      if (returnUrl.indexOf('?') === -1) {
        returnUrl += '?';
      } else {
        returnUrl += '&';
      }
      returnUrl += signupConstants.newUserParam;
      $scope.sendConversionEvent(() => (window.location.href = returnUrl));
    } else {
      const landingUrl = signupConstants.urls.homePageNewUser;
      $scope.sendConversionEvent(() => (window.location.href = landingUrl));
    }
  }

  function handleUsePhoneCallback() {
    $scope.switchToPhone();
    $scope.layout.isSubmitting = false;
    $scope.badSubmit = false;
    $scope.$apply();
  }

  function handleUseEmailCallback() {
    $scope.switchToEmail();
    $scope.layout.isSubmitting = false;
    $scope.badSubmit = false;
    $scope.$apply();
  }

  function handleCaptcha(inputParams) {
    $scope.captchaReturnTokenInSuccessCb = true;
    $scope.captchaInputParams = inputParams;
    $scope.captchaActivated = true;
    $scope.layout.isSubmitting = true;
    $scope.incrementEphemeralCounter(signupConstants.counters.captcha);
  }

  function logEvent(event) {
    eventTrackerService.fireEvent(event);
  }

  function cleanupIdentityVerificationResultToken() {
    localStorageService.removeLocalStorage('identityVerificationResultToken');
  }

  function identityVerificationResultTokenErrorHandler() {
    cleanupIdentityVerificationResultToken();
    window.location.href = urlService.getAbsoluteUrl(signupConstants.urls.koreaIdVerification);
  }

  $scope.isKoreaAdultUser = function () {
    return !$scope.isPrefilledBirthdayUnder18 && $scope.isKoreaIdVerificationEnabled;
  };

  $scope.init();
}

landingPageModule.controller('signupController', signupController);

export default signupController;
