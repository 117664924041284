import landingPageModule from "../landingPageModule"
import { EnvironmentUrls, FormEvents, EventStream } from "Roblox";

function landingPageController($scope, $window, landingPageConstants, landingPageService, modalService, languageResource) {
    "ngInject";
    
    $scope.landingLayout = {
        googlePlayStoreLink: EnvironmentUrls.googlePlayStoreLink,
        amazonStoreLink: EnvironmentUrls.amazonStoreLink,
        appStoreLink: EnvironmentUrls.appStoreLink,
        windowsStoreLink: EnvironmentUrls.windowsStoreLink,
        xboxStoreLink: EnvironmentUrls.xboxStoreLink,
        loginLink: EnvironmentUrls.websiteUrl + landingPageConstants.urls.login
    };

    $scope.appClick = function (name) {
        sendInteractionClickEvent(name + landingPageConstants.appButtonClickEvent);
    }

    $scope.loadContentRatingLogoPolicy = function() {
        landingPageService.getContentRatingLogoPolicy().then(
            response => {
                $scope.displayBrazilRatingLogo = response.displayBrazilRatingLogo;
                $scope.displayItalyRatingLogo = response.displayItalyRatingLogo;
            },
            error => {
                console.debug(error);
            }
        );
    }

    $scope.contentRatingLogoClick = function (country) {
        var modal = modalService.open({
            cssClass: landingPageConstants.modalConstants.modalDarkTheme,
            actionButtonClass: landingPageConstants.modalConstants.actionButtonTheme,
            titleText: languageResource.get("Heading.LeavingRoblox"),
            bodyText: languageResource.get("Description.ExternalWebsiteRedirect"),
            actionButtonShow: true,
            actionButtonText: languageResource.get("Action.Continue"),
            neutralButtonText: languageResource.get("Action.Cancel")
        });
        
        var ratingGuideUrl;
        switch (country) {
            case 'brazil':
                ratingGuideUrl = landingPageConstants.urls.brazilContentRatingGuide;
                break;
            case 'italy':
                ratingGuideUrl = landingPageConstants.urls.italyContentRatingGuide;
                break;
            default:
                console.debug(`No rating guide URL exists for country: ${country}.`)
        }

        modal.result.then(function() {
            $window.open(ratingGuideUrl, "_blank");
        }, angular.noop);
    }

    $scope.isKoreaSignInVerification = $window.location.pathname === landingPageConstants.urls.koreaIdVerification;

    function sendInteractionClickEvent(fieldName) {
        if (FormEvents) {
            FormEvents.SendInteractionClick(landingPageConstants.context, fieldName);
        }
    }

    function sendResolutionEvent() {
        if (EventStream && EventStream.SendEvent) {
            var properties = {
                screenResolution: $window.screen.width * $window.devicePixelRatio + "x" + $window.screen.height * $window.devicePixelRatio,
                actualScreenSize: $window.screen.width + "x" + $window.screen.height,
                zoomRatio: $window.devicePixelRatio,
                windowSize: $window.innerWidth + "x" + $window.innerHeight
            };
            EventStream.SendEvent(landingPageConstants.resolutionEvent, landingPageConstants.context, properties);
        }
    }

    function init() {
        sendResolutionEvent();

        $scope.displayBrazilRatingLogo = false;
        $scope.displayItalyRatingLogo = false;
        $scope.loadContentRatingLogoPolicy();
    };

    init();
};

landingPageModule.controller("landingPageController", landingPageController);

export default landingPageController;
