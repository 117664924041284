import landingPageModule from "../landingPageModule"

function rbxValidRetypePassword() {
    "ngInject";

    return {
        require: 'ngModel',
        link: function (scope, ele, attrs, ctrl) {
            // $watch will monitor the return value and re-set validity if it changes
            scope.$watch(function () {
                return !angular.isUndefined(ctrl.$modelValue) && ctrl.$modelValue.length > 0 
                && scope.signupForm.signupPassword.$valid && ctrl.$modelValue == scope.signup.password;
            }, function (currentValue) {
                ctrl.$setValidity('retypePassword', currentValue);
            });
        }
    }
}
landingPageModule.directive("rbxValidRetypePassword", rbxValidRetypePassword);

export default rbxValidRetypePassword;