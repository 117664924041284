import { EnvironmentUrls } from 'Roblox';
import { eventStreamService } from 'core-roblox-utilities';
import landingPageModule from '../landingPageModule';
import events from '../constants/verifiedSignupEventStreamConstants';

function signupService(httpService, signupConstants) {
  'ngInject';

  function signup(params, signUpUrl) {
    const url = signUpUrl || EnvironmentUrls.authApi + signupConstants.urls.signUpApi;
    const urlConfig = {
      url
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  function getVerifiedSignupChallenge(deliveryTarget) {
    const url = EnvironmentUrls.authApi + signupConstants.urls.verifiedSignupChallenge;
    const urlConfig = {
      url
    };
    const params = {
      deliveryMethod: 0,
      deliveryTarget
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  function getVerifiedSignupVoucher(challenge, code) {
    const url = EnvironmentUrls.authApi + signupConstants.urls.verifiedSignupVoucher;
    const urlConfig = {
      url
    };
    const params = {
      challenge,
      code
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  function verifiedSignup(params) {
    const url = EnvironmentUrls.authApi + signupConstants.urls.verifiedSignup;
    const urlConfig = {
      url
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  // This call is used for experimentation / AB testing variants
  function getMetadataV2() {
    const url = EnvironmentUrls.authApi + signupConstants.urls.metadataV2;
    const urlConfig = {
      url
    };

    return httpService.httpGet(urlConfig);
  }

  function getUserAgreements() {
    const urlConfig = signupConstants.apiUrls.userAgreementsAcceptanceApi;

    return httpService.httpGet(urlConfig);
  }

  function sendSignupEvent(event, origin) {
    eventStreamService.sendEventWithTarget(event.type, event.context, {
      ...event.params,
      origin: origin || events.DefaultVerifiedSignupOrigin
    });
  }

  return {
    signup,
    getMetadataV2,
    getVerifiedSignupChallenge,
    getVerifiedSignupVoucher,
    verifiedSignup,
    getUserAgreements,
    sendSignupEvent
  };
}

landingPageModule.factory('signupService', signupService);

export default signupService;
